import { request } from "../functions/request";
export async function getBanners() {
  return request(`/main/banner_list.php?ver=1`);
}
export async function getHits() {
  return request(`/main/hit.php`, {
    next: {
      revalidate: 60 * 60
    }
  });
}
export async function getSales() {
  return request(`/sales/get_list.php?index=true`, {
    next: {
      revalidate: 60 * 60
    }
  });
}
export async function getRecommended() {
  return request(`/main/recommend.php`, {
    next: {
      revalidate: 60 * 60
    }
  });
}
export async function getPopularCategories() {
  return request(`/main/section.php`, {
    next: {
      revalidate: 60 * 60
    }
  });
}
export async function getSeason() {
  return request(`/main/ready_season.php`, {
    next: {
      revalidate: 60 * 60
    }
  });
}
export async function getWeekendHits() {
  return request(`/collections/?iblockcode=weekly-hits&filter_collections[ACTIVE_DATE]=Y&filter_collections[ACTIVE]=Y`, {
    next: {
      revalidate: 60 * 60
    }
  });
}
export async function getFutureSales() {
  return request(`/infoblock/get_list.php?iblockcode=future_sale&select[name]=NAME&select[img]=PREVIEW_PICTURE&select[link]=PROPERTY_ITEM_CODE&filter[ACTIVE_DATE]=Y&join_select[PROPERTY_ITEM_CODE_VALUE]=/card/`, {
    next: {
      revalidate: 60 * 60
    }
  });
}